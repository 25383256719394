export const DEFAULT_FEEDBACK_MODAL_STATE: FeedbackModalReduxState = {
    modalType: undefined,
}

export interface FeedbackModalReduxState {
    modalType?: FeedbackModalType
}

export enum FeedbackPromptType {
    RATING = 'rating',
    TEXT = 'text',
    SELECT = 'select',
    MULTISELECT = 'multiSelect',
    BOOLEAN = 'boolean',
}

export enum FeedbackModalType {
    INCIDENT_HELP = 'INCIDENT HELP',
    INCIDENT_CLOSED = 'INCIDENT CLOSED',
    INCIDENT_UNASSIGNED = 'INCIDENT UNASSIGNED',
}
