export enum IncidentsDetailType {
    History = 'Incident history',
    Recommendations = 'Recommended Actions',
}

interface RecommendationType {
    expanded: boolean
    modalOpen: boolean
}

const DEFAULT_RECOMMENDATION: RecommendationType = {
    expanded: true,
    modalOpen: false,
}

export interface DetailExpandedType {
    historyExpanded: boolean
    recommendationsExpanded: RecommendationType
}
export const DEFAULT_INCIDENTS_DETAIL_EXPANDED: DetailExpandedType = {
    historyExpanded: true,
    recommendationsExpanded: DEFAULT_RECOMMENDATION,
}
