import {Action} from './actions'
import ActionType from './action-type'
import produce from 'immer'
import {DEFAULT_FEEDBACK_MODAL_STATE, FeedbackModalReduxState, FeedbackModalType} from './state'

const feedbackModalReducer = produce(
    (draft: FeedbackModalReduxState = DEFAULT_FEEDBACK_MODAL_STATE, action: Action) => {
        switch (action.type) {
            case ActionType.INCIDENT_CLOSED_FEEDBACK:
                draft.modalType = FeedbackModalType.INCIDENT_CLOSED
                break
            case ActionType.INCIDENT_HELP_FEEDBACK:
                draft.modalType = FeedbackModalType.INCIDENT_HELP
                break
            case ActionType.INCIDENT_UNASSIGNED_FEEDBACK:
                draft.modalType = FeedbackModalType.INCIDENT_UNASSIGNED
                break
            case ActionType.FEEDBACK_CLOSED:
                draft.modalType = undefined
                break
            default:
                break
        }

        return draft
    },
)
export default feedbackModalReducer
