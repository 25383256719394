import produce from 'immer'
import {LoggingOutAction} from '../../../../../../store/state/session-data/actions'
import {TagsComponentState} from '../types/tags-component-state'
import {AllActions} from './actions'
import SessionActionType from '../../../../../../store/state/session-data/action-type'
import {ActionType} from './action-type'
import LoadingState from '../../../../../../values/loading-state-enum'
import {isEqual} from 'lodash'
import {DEFAULT_TAG_COMPONENT_STATE} from '../types/default-tag-component-state'

export const tagComponentReducer = produce(
    (draft: TagsComponentState, action: AllActions | LoggingOutAction) => {
        switch (action.type) {
            case SessionActionType.LOGGING_OUT:
                draft = DEFAULT_TAG_COMPONENT_STATE
                break
            case ActionType.RECEIVE_PREFS_FILTER:
                draft.loadingFilterState = LoadingState.Loaded
                if (!isEqual(draft.tagsComponentFilter.sortColumn, action.payload.sortColumn)) {
                    draft.tagsComponentFilter.sortColumn = action.payload.sortColumn
                }
                draft.tagsComponentFilter.timeRange = action.payload.timeRange
                if (
                    !isEqual(
                        draft.tagsComponentFilter.searchVesselTagTerm,
                        action.payload.searchVesselTagTerm,
                    )
                ) {
                    draft.tagsComponentFilter.searchVesselTagTerm =
                        action.payload.searchVesselTagTerm
                }
                break
            case ActionType.REQUEST_FILTER:
                draft.loadingFilterState = LoadingState.RequestingData
                break
            case ActionType.SET_SORT_COLUMN:
                if (!isEqual(draft.tagsComponentFilter.sortColumn, action.payload)) {
                    draft.tagsComponentFilter.sortColumn = action.payload
                }
                break
            case ActionType.SHOW_FILTER:
                draft.showFilter = action.payload
                break
            case ActionType.SET_TIME_RANGE:
                draft.tagsComponentFilter.timeRange = action.payload
                break
            case ActionType.SET_SEARCH_VESSEL_TAG_TERM:
                draft.tagsComponentFilter.searchVesselTagTerm = action.payload
                break
            case ActionType.SET_SEARCH_VESSEL_TERM:
                draft.tagsComponentFilter.searchVesselTerm = action.payload
                break
            case ActionType.TOGGLE_VESSEL_TAG:
                if (action.payload.newValue) {
                    draft.tagsComponentFilter.searchVesselTagTerm.push(action.payload.tag)
                } else {
                    draft.tagsComponentFilter.searchVesselTagTerm =
                        draft.tagsComponentFilter.searchVesselTagTerm.filter(
                            (tagTerm) => tagTerm !== action.payload.tag,
                        )
                }
                break

            case ActionType.SET_LATEST_INCIDENTS:
                draft.latestIncidents = action.payload
                break

            case ActionType.SET_LATEST_USB:
                draft.latestUSB = action.payload
                break

            case ActionType.SET_LOADING_USB:
                draft.loadingUSB = action.payload
                break
            case ActionType.SET_LOADING_INCIDENTS:
                draft.loadingIncidents = action.payload
                break
        }
        return draft
    },
)
