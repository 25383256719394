import {ActionType} from './action-type'
import {
    DEFAULT_TAGS_COMPONENT_FILTERS,
    SortColumnType,
    TagsComponentFilter,
} from '../types/tags-component-filter'
import {Dispatch} from 'react'
import {REST} from '../../../../../../index'
import * as Actions from './actions'
import {TIME_RANGE_VALUES} from '../../../../../../values/TimeRangeValues'
import {VesselTags} from '../../../../../../store/state/vessel-tags/state'
import {
    INCIDENTS_ENDPOINT,
    USB_SUMMARY_ENDPOINT,
} from '../../../../../vessels-beta/contexts/types/vessels-beta-endpoints'
import {getRelativeTimeRange} from '../../../../../../utils/Utils'
import {TimeRange} from '../../../../../../values/TimeRange'
import LoadingState from '../../../../../../values/loading-state-enum'

const USER_PREFS_URL = '/api/v1/userPreferences/tags_component'

export function fetchTagsComponentFilter(
    dispatch: Dispatch<Actions.AllActions>,
    assignedTagName: string | undefined,
): void {
    dispatch(requestFilter())

    REST.get(USER_PREFS_URL).then(
        (response) => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_TAGS_COMPONENT_FILTERS,
                    ...response.data.value,
                    searchVesselTagTerm: response.data.value.searchVesselTagTerm.includes(
                        assignedTagName,
                    )
                        ? [...response.data.value.searchVesselTagTerm]
                        : assignedTagName
                          ? [...response.data.value.searchVesselTagTerm, assignedTagName]
                          : [...response.data.value.searchVesselTagTerm],
                }),
            )
        },
        () => {
            dispatch(
                receivePrefsFilter({
                    ...DEFAULT_TAGS_COMPONENT_FILTERS,
                    searchVesselTagTerm: assignedTagName ? [assignedTagName] : [],
                }),
            )
        },
    )
}

export function setSortColumn(
    sortColumn: SortColumnType,
    dispatch: Dispatch<Actions.SetSortColumnAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_SORT_COLUMN,
        payload: sortColumn,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, sortColumn: sortColumn})
}

export function setTimeRange(
    timeRange: TIME_RANGE_VALUES,
    dispatch: Dispatch<Actions.RequestTimeRange>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_TIME_RANGE,
        payload: timeRange,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, timeRange: timeRange})
}

function receivePrefsFilter(
    receivedTagsComponentFiler: TagsComponentFilter,
): Actions.ReceivePrefsFilterAction {
    return {
        type: ActionType.RECEIVE_PREFS_FILTER,
        payload: receivedTagsComponentFiler,
    }
}

function requestFilter(): Actions.RequestFilterAction {
    return {
        type: ActionType.REQUEST_FILTER,
    }
}

export function showFilter(show = false): Actions.ShowFilterAction {
    return {
        type: ActionType.SHOW_FILTER,
        payload: show,
    }
}

export function setSearchVesselTagTerm(
    searchedVesselTagTerm: string[],
    dispatch: Dispatch<Actions.SetSearchVesselTagTermAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TAG_TERM,
        payload: searchedVesselTagTerm,
    })
    REST.put(USER_PREFS_URL, {...currentFilter, searchVesselTagTerm: searchedVesselTagTerm})
}

export function setSearchVesselTerm(
    searchTerm: string,
    dispatch: Dispatch<Actions.SetSearchVesselTermAction>,
): void {
    dispatch({
        type: ActionType.SET_SEARCH_VESSEL_TERM,
        payload: searchTerm,
    })
}

export function toggleVesselTag(
    allTags: VesselTags[],
    tag: string,
    newValue: boolean,
    dispatch: Dispatch<Actions.ToggleVesselTagAction>,
    currentFilter: TagsComponentFilter,
): void {
    dispatch({
        type: ActionType.TOGGLE_VESSEL_TAG,
        payload: {
            allTags,
            tag,
            newValue,
        },
    })
    const searchedVesselTagTerm = newValue
        ? currentFilter.searchVesselTagTerm.length === 0
            ? new Array(tag)
            : [...currentFilter.searchVesselTagTerm, tag]
        : currentFilter.searchVesselTagTerm.filter((tagTerm) => tagTerm !== tag)
    REST.put(USER_PREFS_URL, {...currentFilter, searchVesselTagTerm: searchedVesselTagTerm})
}

export function fetchLatestIncidents({
    dispatch,
    locations: locations,
    period,
}: {
    dispatch: Dispatch<Actions.AllActions>
    locations: string[]
    period: TimeRange
}) {
    dispatch({type: ActionType.SET_LOADING_INCIDENTS, payload: LoadingState.RequestingData})
    const relative = getRelativeTimeRange(period)
    REST.post(`${INCIDENTS_ENDPOINT}/find`, {
        createdFromRelative: relative,
        unseenIncidentResponses: false,
        locations: locations,
    })
        .then((response) => {
            dispatch({type: ActionType.SET_LOADING_INCIDENTS, payload: LoadingState.Loaded})
            dispatch({
                type: ActionType.SET_LATEST_INCIDENTS,
                payload: response.data.data,
            })
        })
        .catch(() => {
            dispatch({type: ActionType.SET_LOADING_INCIDENTS, payload: LoadingState.Errored})
        })
}

export function fetchLatestUSB({
    dispatch,
    locations: locations,
    period,
}: {
    dispatch: Dispatch<Actions.AllActions>
    locations: string[]
    period: TimeRange
}) {
    dispatch({type: ActionType.SET_LOADING_USB, payload: LoadingState.RequestingData})
    REST.post(`${USB_SUMMARY_ENDPOINT}/find/summary`, {
        fromRelativeFirstSeen: `-${period.toHourString().toLowerCase()}`,
        locations: locations,
    })
        .then((response) => {
            dispatch({type: ActionType.SET_LOADING_USB, payload: LoadingState.Loaded})
            dispatch({
                type: ActionType.SET_LATEST_USB,
                payload: response.data,
            })
        })
        .catch(() => {
            dispatch({type: ActionType.SET_LOADING_USB, payload: LoadingState.Errored})
        })
}
