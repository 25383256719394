enum ActionType {
    REQUEST_FILTER = '[NETWORK ASSETS FILTER] REQUEST FILTER',
    RECEIVE_FILTER = '[NETWORK ASSETS FILTER] RECEIVE FILTER',
    SET_SORT_COLUMN = '[NETWORK ASSETS FILTER] SET SORT COLUMN',
    SET_SEARCHED_HOSTNAME = '[NETWORK ASSETS FILTER] SET SEARCHED HOSTNAME',
    SET_SEARCHED_PROPERTIES = '[NETWORK ASSETS FILTER] SET SEARCHED PROPERTIES',
    SET_SEARCHED_IP_ADDRESS = '[NETWORK ASSETS FILTER] SET SEARCHED IP ADDRESS',
    SET_SEARCHED_MAC_DESCRIPTION = '[NETWORK ASSETS FILTER] SET SEARCHED MAC DESCRIP',
    SET_SEARCHED_LAST_ACTIVE = '[NETWORK ASSETS FILTER] SET SEARCHED LAST ACTIVE',
    TOGGLE_NETWORK_ASSET_STATE = '[NETWORK ASSETS FILTER] TOGGLE NETWORK ASSET STATE',
    TOGGLE_ALL_NETWORK_ASSET_STATE = '[NETWORK ASSETS FILTER] TOGGLE ALL NETWORK ASSET STATE',
    TOGGLE_NETWORK = '[NETWORK ASSETS FILTER] TOGGLE NETWORK',
    TOGGLE_ALL_NETWORKS = '[NETWORK ASSETS FILTER] TOGGLE ALL NETWORKS',
    TOGGLE_BEHAVIOUR = '[NETWORK ASSETS FILTER] TOGGLE BEHAVIOUR',
    SELECT_BEHAVIOUR = '[NETWORK ASSETS FILTER] SELECT BEHAVIOUR',
    TOGGLE_ALL_BEHAVIOURS = '[NETWORK ASSETS FILTER] TOGGLE ALL BEHAVIOURS',
    TOGGLE_FILTER_EXPANDED = '[NETWORK ASSETS FILTER] TOGGLE FILTER EXPANDED',
    TOGGLE_DETAIL_EXPANDED = '[NETWORK ASSETS FILTER] TOGGLE DETAIL EXPANDED',
    RESET_FILTER = '[NETWORK ASSETS FILTER] RESET FILTER',
    BULK_ASSIGN_SAVED_FILTER = '[NETWORK ASSETS FILTER] BULK ASSIGN SAVED FILTER',
    SET_SELECTED_FILTER_NAME = '[NETWORK ASSETS FILTER] SET SELECTED FILTER NAME',
    SET_SKIP_SETTING_FILTER = '[NETWORK ASSETS FILTER] SET SKIP SETTING FILTER',
}

export default ActionType
