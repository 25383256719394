import {NetworkAssetState} from '../../pages/network-assets/context/types/isolated-assets-type'
import {NetworkType} from '../../pages/network-assets/context/types/network-type'
import {LocationIdType} from '../../store/state/locations/state'
import {
    DetailExpandedType,
    FilterExpandedType,
    NetworkAssetsSortType,
    TimestampFilterType,
} from '../../store/state/network-assets-filter/state'
import {GuidType} from '../generic-type-defintions'

export interface NetworkAssetsFilterCriteria {
    orderBy: {column: NetworkAssetsSortType; isAscending: boolean}
    states: NetworkAssetState[] | undefined
    networks: NetworkType[] | undefined
    behaviours: GuidType[] | undefined
    properties: string | undefined
    pagination: {pageSize: 10; pageOffset: 0}
    vlan: string | undefined
    macAddress: string | undefined
    fromRelativeLastSeen: TimestampFilterType | undefined
}

export const DEFAULT_NETWORK_ASSETS_FILTER_CRITERIA: NetworkAssetsFilterCriteria = {
    orderBy: {
        column: NetworkAssetsSortType.TIMESTAMP,
        isAscending: false,
    },
    states: undefined,
    networks: undefined,
    behaviours: undefined,
    properties: undefined,
    pagination: {pageSize: 10, pageOffset: 0},
    vlan: undefined,
    macAddress: undefined,
    fromRelativeLastSeen: undefined,
}

export interface NetworkAssetsFilter {
    orderBy: {column: NetworkAssetsSortType; isAscending: boolean}
    states: NetworkAssetState[] | undefined
    networks: NetworkType[] | undefined
    behaviours: GuidType[] | undefined
    properties: string | undefined
    pagination: {pageSize: 10; pageOffset: 0}
    vlan: string | undefined
    macAddress: string | undefined
    fromRelativeLastSeen: TimestampFilterType | undefined
    filterExpanded: FilterExpandedType
    detailExpanded: DetailExpandedType
    selectedVessels: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
}

export const DEFAULT_NETWORK_ASSETS_FILTER: NetworkAssetsFilter = {
    orderBy: {
        column: NetworkAssetsSortType.TIMESTAMP,
        isAscending: false,
    },
    states: undefined,
    networks: undefined,
    behaviours: undefined,
    properties: undefined,
    pagination: {pageSize: 10, pageOffset: 0},
    vlan: undefined,
    macAddress: undefined,
    fromRelativeLastSeen: undefined,
    filterExpanded: {
        assetExpanded: true,
        vesselExpanded: true,
        networkExpanded: true,
        behavioursExpanded: true,
        propertiesExpanded: true,
    },
    detailExpanded: {
        detailsExpanded: true,
        insightsExpanded: true,
        actionsExpanded: true,
    },
    selectedVessels: undefined,
    searchVesselTagTerm: [],
    searchVesselNameTerm: '',
}

export interface NetworkAssetsFilterForUserPref {
    selectedFilterName: string
    locations: LocationIdType[] | undefined
    searchVesselTagTerm: string[]
    searchVesselNameTerm: string
    detailExpanded: DetailExpandedType
}
