import Axios, {AxiosInstance} from 'axios'
import {store} from '../../index'
import {errorOccured} from '../../store/state/auth/action-creators'

const EXPIRED_SESSION = 'Session expired'
const UNAUTHORIZED = 401

export const makeAxios = (): Promise<AxiosInstance> =>
    // load config
    fetch(`/config/${import.meta.env.VITE_ALT_BACKEND ?? 'medulla-ui'}.json`)
        .then((response) => response.json())
        .then((json) =>
            // create axios
            Axios.create({
                baseURL: json.baseUrl,
                timeout: 5000,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        )
        .then((axiosInstance) => {
            // interceptor to add auth token
            axiosInstance.interceptors.request.use((config) => {
                if (config.headers) {
                    config.headers.Authorization = `Bearer ${store.getState().auth.authToken}`
                }

                return config
            })

            axiosInstance.interceptors.response.use(
                (response) => response,
                (error) => {
                    if (error && error.response && error.response.status === UNAUTHORIZED) {
                        store.dispatch(errorOccured(EXPIRED_SESSION))

                        return Promise.reject(error)
                    } else {
                        return Promise.reject(error)
                    }
                },
            )

            axiosInstance.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
                const config = err.config
                // If config does not exist or the retry option is not set, reject
                if (!config || !config.retry) {
                    return Promise.reject(err)
                }

                // Set the variable for keeping track of the retry count
                config.__retryCount = config.__retryCount || 0

                // Check if we've maxed out the total number of retries
                if (config.__retryCount >= config.retry) {
                    // Reject with the error
                    return Promise.reject(err)
                }

                // Increase the retry count
                config.__retryCount += 1

                // Create new promise to handle exponential backoff
                const backoff = new Promise<void>(function (resolve) {
                    setTimeout(function () {
                        resolve()
                    }, config.retryDelay || 1)
                })

                // Return the promise in which recalls axios to retry the request
                return backoff.then(function () {
                    return axiosInstance(config)
                })
            })

            return axiosInstance
        })
