export function CustomLogo(): JSX.Element {
    const partnerLogoRequest = new XMLHttpRequest()

    partnerLogoRequest.open(
        'GET',
        `${import.meta.env.BASE_URL}partner-assets/customLogo.base64`,
        false,
    )

    partnerLogoRequest.send()

    if (partnerLogoRequest.status !== 200) {
        return <div />
    }

    const logoResponse = partnerLogoRequest.responseText
    return <img src={`data:image/png;base64,${logoResponse}`} alt={'Partner Logo'} />
}
