import _ from 'lodash'
import {TagsWithLocations} from './types'
import {Location} from '../../../store/state/locations/state'
import {VesselTags} from '../../../store/state/vessel-tags/state'
import {OnChangeData} from './types'
import {NoTagSymbol} from './types'

export function computeFilterState(data: OnChangeData): {
    wholeFleet: boolean
    // All fleet selected for the tag
    completeTags: string[]
    // Fleet without any tags that is selected
    noTags: number
    // Number of selected vessels
    noOfSelected: number
} {
    let wholeFleet = false
    const completeTags: string[] = []
    let noTags = 0

    if (data.unselectedVessels.length === 0) {
        wholeFleet = true
    }

    for (const [key] of Object.entries(data.selectedVesselsByTag)) {
        // Means there is unselected vessel for this tag
        if (data.unselectedVesselsByTag[key] !== undefined) {
            continue
        }

        completeTags.push(key)
    }

    // Object.entries does not iterate through symbol.
    // So we have to handle it differently
    if (data.selectedVesselsByTag[NoTagSymbol] !== undefined) {
        noTags = data.selectedVesselsByTag[NoTagSymbol].length
    }

    return {
        wholeFleet,
        completeTags,
        noTags,
        noOfSelected: data.selectedVessels.length,
    }
}

export function getTagsWithLocations(locations: Location[], tags: VesselTags[]) {
    let tagsWithLocation: TagsWithLocations = new Map()

    for (const vessel of locations) {
        // Assign vessel with no tags
        // to its own special category
        if (!vessel.tags || vessel.tags.length === 0) {
            if (!tagsWithLocation.has(NoTagSymbol)) {
                tagsWithLocation.set(NoTagSymbol, [])
            }
            tagsWithLocation.get(NoTagSymbol)!.push(vessel)
            // Skip to next iteration since this vessel has no tags
            continue
        }

        // Process tags for vessels that have them
        for (const tag of vessel.tags) {
            // Skip if user don't have access to the tag
            const tagExist = tags.find((okTag) => okTag.identity === tag.identity)
            if (!tagExist) {
                continue
            }

            if (!tagsWithLocation.has(tag.name)) {
                tagsWithLocation.set(tag.name, [])
            }
            tagsWithLocation.get(tag.name)!.push(vessel)
        }
    }

    // Sort tagsWithLocation alphabetically by tag name,
    // lodash will put symbol at the end
    tagsWithLocation = new Map(_.sortBy(Array.from(tagsWithLocation), [(tag) => tag[0]]))
    // Sort locations in the tags alphabetically
    for (const [tag, locations] of tagsWithLocation) {
        tagsWithLocation.set(
            tag,
            _.sortBy(locations, (vessel) => vessel.description),
        )
    }

    return tagsWithLocation
}
