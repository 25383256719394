import {TagsComponentState} from './tags-component-state'
import {DEFAULT_TAGS_COMPONENT_FILTERS} from './tags-component-filter'
import LoadingState from '../../../../../../values/loading-state-enum'

export const DEFAULT_TAG_COMPONENT_STATE: TagsComponentState = {
    loadingFilterState: LoadingState.NotPopulated,
    tagsComponentFilter: DEFAULT_TAGS_COMPONENT_FILTERS,
    showFilter: false,
    latestIncidents: [],
    latestUSB: [],
    loadingIncidents: LoadingState.NotPopulated,
    loadingUSB: LoadingState.NotPopulated,
}
