import {useContext} from 'react'
import {tagsComponentContext} from './tags-component-context'
import {SortColumnType} from './types/tags-component-filter'
import {warn} from '../../../../../helpers/logging'
import * as ActionCreators from './state/action-creators'
import {TIME_RANGE_VALUES} from '../../../../../values/TimeRangeValues'
import {VesselTags} from '../../../../../store/state/vessel-tags/state'
import {TimeRange} from '../../../../../values/TimeRange'
import {getTagsWithLocations} from '../../../../../components/headless/vessel-filter/utils'
import useTypedSelector from '../../../../../hooks/use-typed-selector'
import {locationsSelector} from '../../../../../store/state/locations/selectors'
import {vesselTagsSelector} from '../../../../../store/state/vessel-tags/selectors'

function tagTimeToTimeRange(tagTime: string): TimeRange {
    switch (tagTime) {
        case 'PT24H':
            return new TimeRange(1, 'h', '1 Hour')
        case 'PT168H':
            return new TimeRange(7, 'd', '7 Days')
        case 'PT720H':
            return new TimeRange(30, 'd', '30 Days')
        case 'PT2160H':
            return new TimeRange(90, 'd', '90 Days')
        default:
            return new TimeRange(1, 'h', '1 Hour')
    }
}

export function useTagsComponent() {
    const {state, dispatch} = useContext(tagsComponentContext)
    const allLocations = useTypedSelector(locationsSelector)
    const allTags = useTypedSelector(vesselTagsSelector)
    if (state == undefined || dispatch == undefined) {
        throw new Error('useTagsComponent must be used within a TagsComponentContext')
    }

    function setNewSortColumn(sortColumn: SortColumnType): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSortColumn(sortColumn, dispatch, state.tagsComponentFilter)
    }

    function setNewTimerange(timeRange: TIME_RANGE_VALUES): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setTimeRange(timeRange, dispatch, state.tagsComponentFilter)
    }

    function setSearchVesselTagTerm(value: string[]): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTagTerm(value, dispatch, state.tagsComponentFilter)
    }

    function setSearchVesselTerm(value: string): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.setSearchVesselTerm(value, dispatch)
    }

    function fetchFilter(assignedTagName: string | undefined): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.fetchTagsComponentFilter(dispatch, assignedTagName)
    }

    function setShowFilter(show = false): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        dispatch(ActionCreators.showFilter(show))
    }

    function toggleVesselTag(allTags: VesselTags[], tags: string, newValue: boolean): void {
        if (!dispatch) {
            warn('dispatch is not defined')
            return
        }
        ActionCreators.toggleVesselTag(allTags, tags, newValue, dispatch, state.tagsComponentFilter)
    }

    const getLocationList = () => {
        const filteredTags = state.tagsComponentFilter.searchVesselTagTerm.flatMap((searchedTag) =>
            allTags.filter((tags) => tags.name === searchedTag),
        )
        const tagsWithLocations = getTagsWithLocations(allLocations, allTags)

        const locations: string[] = []

        // Find for all if no tag filter
        if (filteredTags.length === 0) {
            locations.push(...allLocations.map((location) => location.location))
        } else {
            // Find only for tags
            for (const tag of filteredTags) {
                const tagLocations = tagsWithLocations.get(tag.name)
                if (tagLocations) {
                    locations.push(...tagLocations.map((location) => location.location))
                }
            }
        }
        return locations
    }

    const fetchLatestIncidents = () => {
        ActionCreators.fetchLatestIncidents({
            dispatch,
            period: tagTimeToTimeRange(state.tagsComponentFilter.timeRange),
            locations: getLocationList(),
        })
    }

    const fetchLatestUSB = () => {
        ActionCreators.fetchLatestUSB({
            dispatch,
            period: tagTimeToTimeRange(state.tagsComponentFilter.timeRange),
            locations: getLocationList(),
        })
    }

    return {
        sortColumn: state.tagsComponentFilter.sortColumn,
        loadingFilterState: state.loadingFilterState,
        setNewSortColumn,
        fetchFilter,
        setShowFilter,
        setSearchVesselTagTerm,
        searchedVesselTagTerm: state.tagsComponentFilter.searchVesselTagTerm,
        setSearchVesselTerm,
        searchedVesselTerm: state.tagsComponentFilter.searchVesselTerm,
        showFilter: state.showFilter,
        setNewTimerange,
        timeRange: state.tagsComponentFilter.timeRange,
        toggleVesselTag,
        fetchLatestIncidents,
        latestIncidents: state.latestIncidents,
        fetchLatestUSB,
        latestUSB: state.latestUSB,
        state,
    }
}
