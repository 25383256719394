export type ColorType = string

export const MAX_SCREEN_WIDTH = 1640

export interface CyberOwlTheme {
    colors: {
        text: {
            default: ColorType
            deemphasize: ColorType
            error: ColorType
            warning: ColorType
            success: ColorType
        }
        background: {
            selectedLocationPage: ColorType
            page: ColorType
            default: ColorType
            lowContrast: ColorType
            fade: ColorType
        }
        boxShadow: {
            highContrast: ColorType
        }
        link: {
            primary: ColorType
            disabled: ColorType
        }
        border: {
            lowContrast: ColorType
            active: ColorType
            default: ColorType
        }
        newIndicator: {
            text: ColorType
            flash: ColorType
            background: ColorType
        }
        graph: {
            primary: ColorType
            secondary: ColorType
        }
    }
    login: {
        errorBorder: ColorType
        errorBackground: ColorType
        errorContent: ColorType
    }
    loadingPage: {
        background: ColorType
    }
    taskStatus: {
        assigned: {
            background: ColorType
            text: ColorType
        }
        unassigned: {
            background: ColorType
            text: ColorType
        }
        rejected: {
            background: ColorType
            text: ColorType
        }
        done: {
            background: ColorType
            text: ColorType
        }
    }
    threatScores: {
        title: {
            unknown: {
                background: ColorType
                text: ColorType
            }
            low: {
                background: ColorType
                text: ColorType
            }
            medium: {
                background: ColorType
                text: ColorType
            }
            high: {
                background: ColorType
                text: ColorType
            }
        }
        score: {
            unknown: {
                background: ColorType
                text: ColorType
            }
            lowScoreLowValue: {
                background: ColorType
                text: ColorType
            }
            lowScoreMediumValue: {
                background: ColorType
                text: ColorType
            }
            lowScoreHighValue: {
                background: ColorType
                text: ColorType
            }
            low: {
                background: ColorType
                text: ColorType
            }
            medium: {
                background: ColorType
                background30p: ColorType
                text: ColorType
            }
            high: {
                background: ColorType
                background30p: ColorType
                text: ColorType
            }
        }
    }
    modalDialog: {
        overlay: ColorType
        text: ColorType
        background: ColorType
        divider: ColorType
        dropShadow: ColorType

        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            border: ColorType
        }
        tabs: {
            selected: {
                text: ColorType
                background: ColorType
            }
            notSelected: {
                text: ColorType
                background: ColorType
            }
        }
    }
    incidents: {
        dataArea: {
            background: ColorType
            historyBackground: ColorType
        }
        dataRow: {
            text: ColorType
            editText: ColorType
            background: ColorType
            newBackground: ColorType
            separator: ColorType
            statusBar: {
                new: ColorType
                open: ColorType
                closed: ColorType
                draft: ColorType
            }
            selectedRow: ColorType
            saveNoteBackground: ColorType
            hoverNoteBackground: ColorType
            editNoteBackground: ColorType
            errorModal: ColorType
        }
        incidentStatus: {
            new: ColorType
            open: ColorType
            closed: ColorType
            draft: ColorType
        }
        incidentStatusPopup: {
            background: ColorType
            border: ColorType
        }
        incidentSeverity: {
            active: {
                low: ColorType
                medium: ColorType
                high: ColorType
                critical: ColorType
            }
            opacity: {
                low: ColorType
                medium: ColorType
                high: ColorType
                critical: ColorType
            }
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        incidentAsset: {
            text: ColorType
        }
        newRecordLabel: {
            background: ColorType
            text: ColorType
        }
        filterBar: {
            background: ColorType
            boxShadow: ColorType
            titleSeparator: ColorType
            headingHighlight: ColorType
            search: {
                text: ColorType
                border: ColorType
            }
            sortOrder: {
                text: ColorType
                border: ColorType
                arrow: {
                    icon: ColorType
                    background: ColorType
                }
                direction: ColorType
            }
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
            savedFilters: {
                detailBackground: ColorType
            }
        }
    }
    systemPage: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            dropShadow: ColorType
        }
        link: {
            primary: ColorType
            hoverBackground: ColorType
        }
    }
    vessels: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            dropShadow: ColorType
        }
        link: {
            primary: ColorType
            hoverBackground: ColorType
        }
        modal: {
            boxShadow: ColorType
            hoverBackgroundColor: ColorType
            hoverTextColor: ColorType
        }
        button: {
            hoverBorderColor: ColorType
            hoverBackgroundColor: ColorType
            hoverTextColor: ColorType
        }
        list: {hoverBackgroundColor: ColorType; hoverTextColor: ColorType}
    }
    navigationResponsive: {
        dataArea: {
            background: ColorType
            miniBackground: ColorType
            locationExpandedArea: ColorType
            miniTextColor: ColorType
            textColor: ColorType
        }
        active: {
            background: ColorType
            underLine: ColorType
        }
    }
    unknownAssets: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            selectedRow: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        filterBar: {
            boxShadow: ColorType
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
            titleSeparator: ColorType
        }
    }
    userManagement: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            selectedRow: ColorType
            dropShadow: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
    }
    vesselFilter: {
        filterBar: {
            background: ColorType
            boxShadow: ColorType
            titleSeparator: ColorType
            headingHighlight: ColorType
            search: {
                text: ColorType
                border: ColorType
            }
            sortOrder: {
                text: ColorType
                border: ColorType
                arrow: {
                    icon: ColorType
                    background: ColorType
                }
                direction: ColorType
            }
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
        }
        dataRow: {
            text: ColorType
            background: ColorType
            separator: ColorType
        }
    }
    myVessels: {
        dataArea: {
            background: ColorType
        }
        actionButton: {
            text: ColorType
            background: ColorType
            border: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        zoomLevel: {
            border: ColorType
            selected: {
                text: ColorType
                background: ColorType
            }
            unselected: {
                hoverBackground: ColorType
                background: ColorType
            }
        }
        filterBar: {
            background: ColorType
            boxShadow: ColorType
            titleSeparator: ColorType
            headingHighlight: ColorType
            search: {
                text: ColorType
                border: ColorType
            }
            sortOrder: {
                text: ColorType
                border: ColorType
                arrow: {
                    icon: ColorType
                    background: ColorType
                }
                direction: ColorType
            }
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
        }
        cards: {
            background: ColorType
            lowRiskText: ColorType
            lowRiskBackground: ColorType
            mediumRiskText: ColorType
            mediumRiskBackground: ColorType
            highRiskText: ColorType
            highRiskBackground: ColorType
            boxShadow: ColorType
        }
    }
    reports: {
        dataArea: {
            background: ColorType
            boxShadow: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            separator: ColorType
        }
        starRating: {
            selected: {
                fill: ColorType
                background: ColorType
            }
            unSelected: {
                fill: ColorType
                background: ColorType
            }
            hover: {
                fill: ColorType
                background: ColorType
            }
            staticIcon: {
                selected: ColorType
                unSelected: ColorType
            }
        }
        actionButton: {
            text: ColorType
            background: ColorType
            border: ColorType
        }
        filterBar: {
            boxShadow: ColorType
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
        }
    }
    usbDevices: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        filterBar: {
            boxShadow: ColorType
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
        }
    }
    softwareInventory: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            selectedRow: ColorType
        }
        resetButton: {
            text: ColorType
            background: ColorType
        }
        filterBar: {
            boxShadow: ColorType
            closeButton: {
                text: ColorType
                background: ColorType
                border: ColorType
            }
            titleSeparator: ColorType
        }
    }
    pagingButton: {
        clickable: {
            default: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
            hover: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
            active: {
                background: ColorType
                text: ColorType
                border: ColorType
            }
        }
        selected: {
            background: ColorType
            text: ColorType
        }
        disabled: {
            background: ColorType
            text: ColorType
            border: ColorType
        }
    }
    refreshButton: {
        default: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        hover: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        active: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
        disabled: {
            borderColor: ColorType
            backgroundColor: ColorType
            textColor: ColorType
        }
    }
    dashboard: {
        noData: {
            text: ColorType
        }
        graphButtons: {
            active: {
                text: ColorType
                background: ColorType
                indicator: ColorType
            }
            selectable: {
                text: ColorType
                background: ColorType
            }
            hover: {
                text: ColorType
                background: ColorType
            }
        }
        fleetRiskScore: {
            low: {
                color: ColorType
                opacity: number
            }
            medium: {
                color: ColorType
                opacity: number
            }
            high: {
                color: ColorType
                opacity: number
            }
        }
        vesselList: {
            backgroundColor: ColorType
            vesselDetail: {
                default: {
                    backgroundColor: ColorType
                }
                expanded: {
                    backgroundColor: ColorType
                }
            }
            assetDetail: {
                default: {
                    backgroundColor: ColorType
                }
                expanded: {
                    backgroundColor: ColorType
                }
            }
        }
    }
    commonTableViewPage: {
        dataArea: {
            background: ColorType
        }
        dataRow: {
            text: ColorType
            background: ColorType
            selectedRow: ColorType
            boxShadow: ColorType
        }
    }
    font: {
        family: string
        weight: {
            light: number
            normal: number
            semibold: number
            bold: number
            extrabold: number
        }
    }
}
