import {Location} from '../../../store/state/locations/state'

export const NoTagSymbol = Symbol('NO_TAG')
export type Tag = string | typeof NoTagSymbol
export type TagsWithLocations = Map<Tag, Location[]>
export interface OnChangeData {
    selectedVessels: Location[]
    selectedVesselsByTag: Record<Tag, Location[]>
    unselectedVessels: Location[]
    unselectedVesselsByTag: Record<Tag, Location[]>
}
