import authReducer from './state/auth/reducer'
import indicatorsReducer from './state/indicators/reducer'
import {nodesReducer} from './state/nodes/reducer'
import threatMeasuresReducer from './state/threat-measures/reducer'
import rolesReducer from './state/roles/reducer'
import configReducer from './state/config/reducer'
import watchedNodesReducer from './state/watched-nodes/reducer'
import SFMReducer from './state/sfm-scores/reducer'
import LocationsReducer from './state/locations/reducer'
import UsersReducer from './state/users/reducer'
import LatestEventTimestampsReducer from './state/latest-event-timestamps/reducer'
import {latestLocationThreatScoresReducer} from './state/latest-location-threat-scores/reducer'
import {myVesselsFiltersReducer} from './state/my-vessels-filters/reducer'
import etsTrendReducer from './state/ets-trend/reducer'
import {etsValueReducer} from './state/ets-value/reducer'
import networkAnomalyMatrixReducer from './state/network-anomaly-matrix/reducer'
import {engineStatusReducer} from './state/engine-status/reducer'
import {selfMonitoringEngineEventsReducer} from './state/self-monitoring-events/reducer'
import {selfMonitoringEngineRulesReducer} from './state/self-monitoring-rules/reducer'
import sessionDataReducer from './state/session-data/reducer'
import {currentUserReducer} from './state/current-user/reducer'
import incidentsFilterReducer from './state/incidents-filter/reducer'
import latestUnknownAssetReducer from './state/latest-unknown-asset/reducer'
import {monaEngineModesReducer} from './state/mona-engine-modes/reducer'
import {incidentTypesReducer} from './state/incident-types/reducer'
import unsavedIncidentNoteAlertModalReducer from './state/unsaved-incident-note-alert-modal/reducer'
import watchedIncidentsReducer from './state/watched-incidents/reducer'
import {emailRecipientsReducer} from './state/email-recipient-management/reducer'
import {vesselTagsReducer} from './state/vessel-tags/reducer'
import {locationTagsSummaryReducer} from './state/location-tags-summary/reducer'
import {vesselFilterReducer} from './state/vessel-filter/reducer'
import reportsFilterReducer from './state/reports-filter/reducer'
import networkAssetsFilterReducer from './state/network-assets-filter/reducer'
import {auditLogReducer} from './state/audit-log/reducer'
import {locationTagThreatScoreReducer} from './state/location-tag-score/reducer'
import usbInventoryFilterReducer from './state/usb-inventory-filter/reducer'
import softwareInventoryFilterReducer from './state/software-inventory-filter/reducer'
import savedFiltersReducer from './state/saved-filters/reducer'
import {extendedDataRequestReducer} from './state/extended-data-request/reducer'
import metricsBetaFilterReducer from './state/metrics-filter-beta/reducer'
import {metricTypesReducer} from './state/metric-types/reducer'
import vesselsBetaFilterReducer from './state/vessels-beta-filter/reducer'
import vesselManagementFilterReducer from './state/vessel-management-filter/reducer'
import OTAssetsReducer from './state/ot-assets/reducer'
import incidentsReportFilterReducer from './state/incidents-report-filter/reducer'
import feedbackModalReducer from './state/feedback/reducer'

export const staticReducers = {
    auth: authReducer,
    config: configReducer,
    indicators: indicatorsReducer,
    roles: rolesReducer,
    nodes: nodesReducer,
    watchedNodes: watchedNodesReducer,
    threatMeasures: threatMeasuresReducer,
    latestLocationThreatScores: latestLocationThreatScoresReducer,
    sfmScores: SFMReducer,
    locations: LocationsReducer,
    users: UsersReducer,
    latestEventTimestamps: LatestEventTimestampsReducer,
    myVesselsFilter: myVesselsFiltersReducer,
    etsTrend: etsTrendReducer,
    etsValue: etsValueReducer,
    networkAnomalyMatrix: networkAnomalyMatrixReducer,
    engineStatus: engineStatusReducer,
    selfMonitoringEngineEvents: selfMonitoringEngineEventsReducer,
    selfMonitoringEngineRules: selfMonitoringEngineRulesReducer,
    sessionData: sessionDataReducer,
    currentUser: currentUserReducer,
    incidentsFilter: incidentsFilterReducer,
    latestUnknownAsset: latestUnknownAssetReducer,
    monaEngineModes: monaEngineModesReducer,
    incidentTypes: incidentTypesReducer,
    unsavedIncidentNoteAlertModal: unsavedIncidentNoteAlertModalReducer,
    watchIncidents: watchedIncidentsReducer,
    emailRecipientData: emailRecipientsReducer,
    vesselTags: vesselTagsReducer,
    locationTagsSummary: locationTagsSummaryReducer,
    vesselFilter: vesselFilterReducer,
    reportsFilter: reportsFilterReducer,
    networkAssetsFilter: networkAssetsFilterReducer,
    usbInventoryFilter: usbInventoryFilterReducer,
    softwareInventoryFilter: softwareInventoryFilterReducer,
    auditLog: auditLogReducer,
    locationTagThreatScore: locationTagThreatScoreReducer,
    savedFilters: savedFiltersReducer,
    extendedDataRequest: extendedDataRequestReducer,
    metricsBetaFilter: metricsBetaFilterReducer,
    metricTypes: metricTypesReducer,
    vesselsBetaFilter: vesselsBetaFilterReducer,
    vesselManagementFilter: vesselManagementFilterReducer,
    OTAssets: OTAssetsReducer,
    incidentsReportFilter: incidentsReportFilterReducer,
    feedbackModal: feedbackModalReducer,
}

export default staticReducers
